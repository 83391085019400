.custom_input { position: relative; }
.custom_input .input:global(.ant-input) { color: #151C32; font-size: 16px; font-weight: 600; line-height: 20px; border: none; border-bottom: 1px solid #E4E5EC; box-shadow: none; border-radius: 0; padding: 10px 0; }
.custom_input .input:global(.ant-input):hover { border-color: #46A1F8; }
.custom_input .input:global(.ant-input)::placeholder { transition: .3s; color: #151C32; font-size: 16px; font-weight: 600; line-height: 20px; letter-spacing: 1.5px; font-family: "Metropolis"; }
.custom_input :global(.ant-form-item) { flex: 1; margin: 0; }
.custom_input .input_title { opacity: 0; transition: .3s; position: absolute; color: #898EA3; letter-spacing: 1.5px; top: -10px; z-index: 100; font-size: 12px; font-weight: 600; line-height: 12px; }
.custom_input.focused .input:global(.ant-input)::placeholder { opacity: 0; }
.custom_input.filled .input_title,
.custom_input.focused .input_title { opacity: 1; }
.custom_input.focused .input:global(.ant-input) { border-color: #46A1F8; }

@media (min-width: 1921px) {
    .custom_input .input:global(.ant-input),
    .custom_input .input:global(.ant-input)::placeholder { font-size: 22px; font-weight: 600; line-height: 26px; }
}

@media (max-width: 1536px) {
    .custom_input .input:global(.ant-input),
    .custom_input .input:global(.ant-input)::placeholder { font-size: 14px; font-weight: 600; line-height: 14px; }
}

@media (max-width: 1366px) {
    /*like in 1536*/
}