.form_block .form_row { display: grid; grid-template-columns: 1fr 1fr; column-gap: 40px; margin-bottom: 40px; }
.form_block .message_input { width: calc(100% - 390px); }

.form_block .send_block { display: flex; justify-content: space-between; align-items: center; margin-bottom: 30px; column-gap: 70px; }
.form_block .send_block .checkboxes_block .checkbox_item { display: flex; }
.form_block .send_block .checkboxes_block .checkbox_item:first-child { margin-bottom: 12px; }
.form_block .send_block .checkboxes_block .checkbox_item .checkbox { margin-right: 20px; }
.form_block .send_block .checkboxes_block .checkbox_item .checkbox_text { max-width: 570px; padding-top: 2px; font-size: 14px; font-weight: 400; line-height: 18px; }
.form_block .send_block .checkboxes_block .checkbox_item .checkbox :global(.ant-form-item-control-input) { display: flex; align-items: flex-start; }
.form_block .send_block .checkboxes_block .checkbox_item .checkbox :global(.ant-form-item-control-input-content),
.form_block .send_block .checkboxes_block .checkbox_item .checkbox :global(.ant-form-item-control-input-content .ant-checkbox-wrapper) { width: 16px; height: 16px; }
.form_block .send_block .send_button { transition: .3s; background: #46A1F8; text-transform: uppercase; letter-spacing: 1.5px; padding: 20px 40px; color: #FFF; transition: .3s; font-size: 16px; font-weight: 700; line-height: 16px; }
.form_block .send_block .send_button:hover { background: #3A83FF; box-shadow: 2px 2px 20px rgba(58, 131, 255, 0.4); }
.form_block .send_block .send_button:disabled { background: rgba(70, 161, 248, 0.7); }
.form_block .send_block .send_button:disabled:hover { box-shadow: none; background: rgba(70, 161, 248, 0.7); }

.form_block .info_text { color: #898EA3; font-size: 14px; font-weight: 400; line-height: 22px; }

.form_block .upload_block { position: relative; margin-bottom: 30px; }
.form_block .upload_block .upload_button { position: absolute; top: -41px; right: 0; width: 200px; display: flex; align-items: center; letter-spacing: 1.5px; border-bottom: 1px solid #E4E5EC; padding: 10px 0; text-transform: uppercase; }
.form_block .upload_block .upload_button > span { color: #151c32; font-size: 14px; font-weight: 600; line-height: 14px; }
.form_block .upload_block .upload_button:hover { border-color: #46A1F8; }
.form_block .upload_block .upload_button > img { margin-right: 12px; width: 20px; height: 20px; }

.form_block :global(.ant-form-item-control-input) { min-height: 16px; }
.form_block :global(.ant-form-item-explain) { position: absolute; }

@media (min-width: 1921px) {
    .form_block .send_block { margin-bottom: 60px; }
    .form_block .send_block .checkboxes_block .checkbox_item .checkbox_text { max-width: 100%; flex: 1; padding-top: 0px; font-size: 17px; font-weight: 400; line-height: 20px; }
    .form_block .send_block .checkboxes_block .checkbox_item .checkbox :global(.ant-form-item-control-input-content),
    .form_block .send_block .checkboxes_block .checkbox_item .checkbox :global(.ant-form-item-control-input-content .ant-checkbox-wrapper) { width: 20px; height: 20px; }
    .form_block .send_block .send_button { padding: 24px 40px; font-size: 20px; font-weight: 700; line-height: 20px; }
    .form_block .info_text { font-size: 17px; font-weight: 400; line-height: 27px; }
    .form_block .upload_block .upload_button { top: -46px; width: 330px; }
    .form_block .upload_block .upload_button > span { font-size: 22px; font-weight: 600; line-height: 22px; }
    .form_block .upload_block .upload_button > img { width: 24px; height: 24px; }
}

@media (max-width: 1536px) {
    .form_block .form_row { column-gap: 30px; }
    .form_block .send_block .checkboxes_block .checkbox_item .checkbox_text { font-size: 13px; font-weight: 500; line-height: 16px; }
    .form_block .send_block .send_button { font-size: 14px; font-weight: 700; line-height: 14px; }
    .form_block .info_text { font-size: 13px; font-weight: 400; line-height: 18px; }
}

@media (max-width: 1366px) {
    /*like in 1536*/
}

@media (max-width: 768px) {
    .form_block .message_input { width: calc(100% - 168px); }
    .form_block .send_block { column-gap: 50px; }
    .form_block .send_block .send_button { padding: 20px; }
    .form_block .upload_block .upload_button { top: -41px; width: 148px; }
}

@media (max-width: 420px) {
    .form_block .form_row { display: flex; flex-direction: column; row-gap: 24px; margin-bottom: 24px; }
    .form_block .message_input { width: 100%; }
    .form_block .upload_block { margin-bottom: 24px; }
    .form_block .upload_block :global(.ant-upload-select) { width: 100%; }
    .form_block .upload_block .upload_button { position: static; width: 100%; margin-top: 24px; }
    .form_block .send_block { flex-direction: column; margin-bottom: 20px; }
    .form_block .send_block .checkboxes_block .checkbox_item .checkbox { margin-right: 12px; }
    .form_block .send_block .checkboxes_block .checkbox_item .checkbox_text { font-size: 14px; font-weight: 400; line-height: 16px; }
    .form_block .send_block .send_button { width: 100%; margin-top: 24px; }
}