* {
    margin: 0;
    padding: 0;
    color: #FFF;
    box-sizing: border-box;
    font-family: "Metropolis";
}

a {
    text-decoration: none;
}

button,
.ant-btn {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    box-shadow: none;
    border-radius: 0;
    height: auto;
}

.ant-modal {
    padding: 0 !important;
}

.ant-modal-body {
    height: 100%;
}

.ant-modal-content {
    padding: 0 !important;
    height: 100%;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.ant-modal-footer {
    display: none;
}

.container {
    margin: 0 auto;
}

@media (max-width: 3840px) {
    .container {
        width: 1880px;
    }
}

@media (max-width: 1920px) {
    .container {
        width: 1386px;
    }
}

@media (max-width: 1536px) {
    .container {
        width: 1176px;
    }
}

@media (max-width: 1366px) {
    .container {
        width: 1166px;
    }
}

@media (max-width: 768px) {
    .container {
        width: 100%;
        padding: 0 45px;
    }
}

@media (max-width: 420px) {
    .container {
        width: 100%;
        padding: 0 25px;
    }
}