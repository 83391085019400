.main_block_component { background: linear-gradient(180deg, #01081F 0%, #18213D 72.17%); width: 100%; height: 100%; position: relative; }
.main_block_component .background_pattern { position: absolute; z-index: 50; top: 0; left: 0; width: 45%; height: auto; opacity: 0.4; }
.main_block_component .wrapper { position: relative; z-index: 100; width: 100%; height: 100%; display: flex; flex-direction: column; background-repeat: no-repeat; background-size: 50% auto; }
.main_block_component .wrapper .content { height: 100%; display: flex; flex-direction: column; }
.main_block_component .wrapper .content .main_block { height: 100%; display: flex; justify-content: space-between; align-items: center; }
.main_block_component .wrapper .content .main_block .title_block { width: 45%; }
.main_block_component .wrapper .content .main_block .title_block .title { margin-bottom: 10px; font-size: 42px; font-weight: 700; line-height: 54px; letter-spacing: 0.5px; }
.main_block_component .wrapper .content .main_block .title_block .description { letter-spacing: 0.5px; color: #46A1F8; font-size: 36px; font-weight: 400; line-height: 46px; }
.main_block_component .wrapper .content .main_block .illustration { padding-top: 91px; width: 50%; height: auto; }

.main_block_component .wrapper .content .discover_more_button { display: flex; align-items: center; column-gap: 12px; position: absolute; bottom: 90px; }
.main_block_component .wrapper .content .discover_more_button > span { color: #46A1F8; letter-spacing: 1.5px; text-transform: uppercase; font-size: 16px; font-weight: 700; line-height: 16px; }

.main_block_component:global(.mobile-web-apps) .wrapper .content .main_block .illustration { width: 640px; height: auto; }

@media (min-width: 1921px) and (max-width: 3840px) {
    .main_block_component .wrapper .content .main_block .title_block .title { font-size: 60px; font-weight: 700; line-height: 78px; }
    .main_block_component .wrapper .content .main_block .title_block .description { font-size: 45px; font-weight: 400; line-height: 59px; }
    .main_block_component .wrapper .content .discover_more_button { bottom: 140px; }
    .main_block_component .wrapper .content .discover_more_button > span { font-size: 18px; font-weight: 700; line-height: 20px; }
    .main_block_component .wrapper .content .main_block .illustration { padding-top: 107px; }
    .main_block_component:global(.mobile-web-apps) .wrapper .content .main_block .illustration { width: 760px; }
}

@media (max-width: 1536px) {
    .main_block_component .wrapper .content .main_block .title_block .title { font-size: 41px; font-weight: 700; line-height: 53px; }
    .main_block_component .wrapper .content .main_block .title_block .description { font-size: 30px; font-weight: 400; line-height: 39px; }
    .main_block_component .wrapper .content .discover_more_button { bottom: 70px; }
    .main_block_component .wrapper .content .discover_more_button > span { font-size: 14px; font-weight: 700; line-height: 16px; }
    .main_block_component:global(.mobile-web-apps) .wrapper .content .main_block .illustration { width: 600px; }
}

@media (max-width: 1366px) {
    .main_block_component .wrapper .content .main_block .title_block .title { font-size: 40px; font-weight: 700; line-height: 52px; }
    .main_block_component .wrapper .content .main_block .title_block .description { font-size: 30px; font-weight: 400; line-height: 39px; }
    .main_block_component .wrapper .content .discover_more_button { bottom: 70px; }
    .main_block_component:global(.mobile-web-apps) .wrapper .content .main_block .illustration { width: 630px; }
}

@media (max-width: 768px) {
    .main_block_component .wrapper .content .main_block { padding-top: 225px; display: flex; flex-direction: column; justify-content: flex-start; }
    .main_block_component .wrapper .content .main_block .title_block { width: 100%; }
    .main_block_component .wrapper .content .main_block .title_block .title { text-align: center; font-size: 38px; font-weight: 700; line-height: 50px; }
    .main_block_component .wrapper .content .main_block .title_block .description { text-align: center; font-size: 28px; font-weight: 400; line-height: 36px; }
    .main_block_component .wrapper .content .main_block .illustration { padding-top: 80px; width: 520px; }
    .main_block_component:global(.mobile-web-apps) .wrapper .content .main_block .illustration { padding: 65px 0 0 0; width: 440px; }
    .main_block_component .wrapper .content .discover_more_button { display: none; }
}

@media (max-width: 420px) {
    .main_block_component .wrapper .content .main_block { padding-top: 167px; }
    .main_block_component .wrapper .content .main_block .title_block .title { text-align: start; font-size: 30px; font-weight: 700; line-height: 38px; margin-bottom: 8px; }
    .main_block_component:global(.main-page) .wrapper .content .main_block .title_block .title { margin-bottom: 60px; }
    .main_block_component .wrapper .content .main_block .title_block .description { text-align: start; font-size: 20px; font-weight: 400; line-height: 26px; }
    .main_block_component .wrapper .content .main_block .illustration { padding-top: 80px; width: 520px; }
    .main_block_component:global(.mobile-web-apps) .wrapper .content .main_block .illustration { padding: 65px 0 0 0; width: 100%; }
    .main_block_component:global(.main-page) .wrapper .content .main_block .illustration { display: none; }
    .main_block_component .wrapper .content .discover_more_button { display: none; }
}