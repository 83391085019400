.column { display: flex; flex-direction: column; row-gap: 24px; max-width: 400px; }
.industries_column .column_title { font-size: 28px; font-weight: 700; line-height: 36px; margin-bottom: 40px; }
.industries_column .industry { display: flex; column-gap: 16px; cursor: pointer; }
.industries_column .industry .circle { width: 12px; height: 12px; border-radius: 100px; background: #46A1F8; margin-top: 4px; }
.industries_column .industry .item_name { flex: 1; font-size: 18px; font-weight: 400; line-height: 20px; transition: .3s; }
.industries_column .industry .item_name:hover { color: #46A1F8; text-shadow: 0px -6px 15px rgba(70, 161, 248, 0.3), 0px 6px 15px rgba(70, 161, 248, 0.3); }

@media (min-width: 1921px) {
    .column { row-gap: 30px; min-width: 275px; }
    .industries_column .column_title { margin-bottom: 60px; }
    .industries_column .industry .circle { width: 14px; height: 14px; margin-top: 5px; }
    .industries_column .industry .item_name { font-size: 24px; font-weight: 400; line-height: 24px; }
}

@media (max-width: 1536px) {
    .industries_column .column_title { font-size: 24px; font-weight: 700; line-height: 36px; }
}

@media (max-width: 1366px) {
    .column { row-gap: 16px; max-width: 350px; }
    .industries_column .column_title { font-size: 22px; font-weight: 700; line-height: 36px; margin-bottom: 30px; }
}

@media (max-width: 768px) {
    .industries_column .industry .circle { width: 12px; height: 12px; margin-top: 4px; }
    .industries_column .industry .item_name { font-size: 18px; font-weight: 400; line-height: 20px; }
}