.contact_us_block { background: #FFF; }
.contact_us_block *:not(button > span) { color: #01081F; margin: 0; box-shadow: none !important; }
.contact_us_block .wrapper { padding: 140px 0; background: url("./img/contact-us-dots-background.svg") no-repeat; background-size: cover; display: flex; flex-direction: column; align-items: center; }
.contact_us_block .wrapper .title_block { display: flex; justify-content: space-between; align-items: center; width: 900px; margin-bottom: 80px; }
.contact_us_block .wrapper .title_block .title { font-size: 50px; font-weight: 700; line-height: 65px; letter-spacing: 0.5px; }
.contact_us_block .wrapper .title_block .email { text-decoration: underline; font-size: 30px; font-weight: 700; line-height: 34px; }
.contact_us_block .wrapper .form_block { width: 1140px; padding: 100px 120px; background: #FFF; }

.contact_us_block.for_top_menu .wrapper { padding: 0; }

@media (min-width: 1921px) {
    .contact_us_block .wrapper .title_block { width: 1160px; }
    .contact_us_block .wrapper .form_block { width: 1440px; padding: 120px 140px 100px; }
}

@media (max-width: 1536px) {
    .contact_us_block .wrapper { padding: 120px 0 100px; }
    .contact_us_block .wrapper .title_block { margin-bottom: 70px; }
    .contact_us_block .wrapper .title_block .title { font-size: 46px; font-weight: 700; line-height: 60px; }
    .contact_us_block .wrapper .title_block .email { font-size: 28px; font-weight: 700; line-height: 30px; }
    .contact_us_block .wrapper .form_block { width: 1030px; padding: 70px 100px; }
}

@media (max-width: 1366px) {
    .contact_us_block .wrapper { padding: 120px 0; }
    .contact_us_block .wrapper .title_block { margin-bottom: 70px; }
    .contact_us_block .wrapper .title_block .title { font-size: 42px; font-weight: 700; line-height: 54px; }
    .contact_us_block .wrapper .title_block .email { font-size: 24px; font-weight: 700; line-height: 26px; }
    .contact_us_block .wrapper .form_block { width: 950px; padding: 70px 100px; }
}

@media (max-width: 768px) {
    .contact_us_block .wrapper { padding: 100px 45px; }
    .contact_us_block .wrapper .title_block { width: 100%; padding: 0 50px; margin-bottom: 50px; }
    .contact_us_block .wrapper .title_block .title { font-size: 38px; font-weight: 700; line-height: 50px; }
    .contact_us_block .wrapper .title_block .email { font-size: 24px; font-weight: 700; line-height: 26px; }
    .contact_us_block .wrapper .form_block { width: 100%; padding: 50px; }
    .contact_us_block.for_top_menu .wrapper .title_block .title { margin-top: 25px; }
}

@media (max-width: 420px) {
    .contact_us_block .wrapper { padding: 0; background: #FFF; }
    .contact_us_block .wrapper .title_block { width: 100%; padding: 60px 25px 0; margin-bottom: 24px; flex-wrap: wrap; }
    .contact_us_block .wrapper .title_block .title { font-size: 34px; font-weight: 700; line-height: 44px; }
    .contact_us_block .wrapper .title_block .email { margin-top: 24px; font-size: 20px; font-weight: 700; line-height: 22px; }
    .contact_us_block .wrapper .form_block { padding: 0 25px 60px; }
    .contact_us_block.for_top_menu .wrapper .title_block .title { font-size: 20px; line-height: 26px; margin-top: 30px; }
}