.main_page { background: linear-gradient(180deg, #01081F 0%, #18213D 72.17%); }
.main_page .main_block { width: 100%; height: 100vh; }

.main_page .cases_block { padding-top: 90px; }
.main_page .cases_block .title_block { display: flex; justify-content: space-between; align-items: center; margin-bottom: 40px; }
.main_page .cases_block .title_block .title { font-size: 34px; font-weight: 700; line-height: 38px; }
.main_page .cases_block .title_block .info { font-size: 22px; font-weight: 400; line-height: 22px; }
.main_page .cases_block .cases { display: flex; flex-direction: column; row-gap: 30px; margin-bottom: 180px; }
.main_page .cases_block .see_all_cases_button_block { padding: 90px 0 180px; display: flex; justify-content: center; }
.main_page .cases_block .see_all_cases_button_block .see_all_cases_button { display: flex; align-items: center; padding: 20px 40px; background: #46A1F8; transition: .3s; }
.main_page .cases_block .see_all_cases_button_block .see_all_cases_button:hover { background: #3A83FF; box-shadow: 2px 2px 20px rgba(58, 131, 255, 0.4); }
.main_page .cases_block .see_all_cases_button_block .see_all_cases_button > img { width: 24px; height: 14px; margin-left: 24px; }
.main_page .cases_block .see_all_cases_button_block .see_all_cases_button span { font-size: 16px; font-weight: 700; line-height: 20px; text-transform: uppercase; letter-spacing: 1.5px; }

@media (max-width: 1536px) {
    .main_page .cases_block { padding-top: 70px; }
    .main_page .cases_block .title_block { margin-bottom: 30px; }
    .main_page .cases_block .cases { margin-bottom: 130px; }
    .main_page .cases_block .see_all_cases_button_block { padding: 70px 0 130px; }
    .main_page .cases_block .title_block .title { font-size: 30px; font-weight: 700; line-height: 34px; }
    .main_page .cases_block .see_all_cases_button_block .see_all_cases_button span { font-size: 14px; font-weight: 700; line-height: 14px; }
}

@media (max-width: 1366px) {
    .main_page .cases_block .cases { margin-bottom: 120px; }
    .main_page .cases_block .see_all_cases_button_block { padding: 70px 0 120px; }
    .main_page .cases_block .title_block .title { font-size: 28px; font-weight: 700; line-height: 30px; }
}

@media (max-width: 768px) {
    .main_page .cases_block { padding-top: 100px; }
    .main_page .cases_block .title_block .title { font-size: 32px; font-weight: 700; line-height: 36px; }
    .main_page .cases_block .title_block { margin-bottom: 40px; }
}

@media (max-width: 420px) {
    .main_page .main_block { height: auto; }
    .main_page .cases_block { padding-top: 60px; }
    .main_page .cases_block .cases { margin-bottom: 60px; }
}