/* @media (max-width: 1279px) {
    .application {
        display: none;
    }

    .mobile {
        background: linear-gradient(100.25deg, #01081F 0.66%, #18213D 89.67%), #01081F;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: red;
        padding: 30px;
    }

    .mobile > p {
        text-align: center;
    }

    .mobile > p:first-child {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 16px;
        font-family: "Metropolis";
    }

    .mobile > p:last-child {
        font-size: 14px;
        font-weight: 400;
        color: #DDEBFE;
        font-family: "Montserrat";
    }
}

@media (min-width: 1280px) {
    .application {
        display: block;
    }

    .mobile {
        display: none;
    }
} */

:global(.fade-appear),
:global(.fade-enter) { opacity: 0; z-index: 1; }

:global(.fade-appear-active),
:global(.fade-enter.fade-enter-active) { opacity: 1; transition: opacity 500ms linear; }

:global(.fade-exit) { opacity: 1; }

:global(.fade-exit.fade-exit-active) { opacity: 0; }