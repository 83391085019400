.main_block { background: linear-gradient(180deg, #01081F 0%, #18213D 88.73%); width: 100%; height: 100%; }
.main_block .background_pattern { position: absolute; z-index: 0; top: 0; right: 0; width: 45%; height: auto; opacity: 0.4; transform: matrix(1, 0, 0, 1, 0, 0); }
.main_block .wrapper { position: relative; z-index: 5; height: 100%; background-repeat: no-repeat; background-position: right top; background-size: 50%; }
.main_block .wrapper .content { min-height: 100vh; display: flex; flex-direction: column; align-items: center; padding: 285px 0 90px; }
.main_block .wrapper .content .title { font-size: 50px; font-weight: 700; line-height: 65px; letter-spacing: 0.5px; text-align: center; }
.main_block .wrapper .content .description { font-size: 22px; font-weight: 400; line-height: 36px; text-align: center; width: 1120px; margin-top: 30px; }
.main_block .wrapper .content .description + .industries_block { margin-top: 130px; }
.main_block .wrapper .content .industries_block { width: 100%; display: flex; justify-content: space-between; margin-top: 150px; column-gap: 95px; }

@media (min-width: 1921px) {
    .main_block { height: 1290px; } 
    .main_block .wrapper .content { padding-top: 307px; }
    .main_block .wrapper .content .title { font-size: 60px; font-weight: 700; line-height: 78px; }
}

@media (max-width: 1536px) {
    .main_block .wrapper .content { padding-top: 230px; }
    .main_block .wrapper .content .title { font-size: 46px; font-weight: 700; line-height: 60px; }
    .main_block .wrapper .content .description { font-size: 20px; font-weight: 400; line-height: 32px; width: 1076px; }
    .main_block .wrapper .content .description + .industries_block,
    .main_block .wrapper .content .industries_block { margin-top: 80px; }
}

@media (max-width: 1366px) {
    .main_block .wrapper .content { padding-top: 210px; }
    .main_block .wrapper .content .title { font-size: 42px; font-weight: 700; line-height: 54px; }
    .main_block .wrapper .content .description { width: 966px; }
    .main_block .wrapper .content .description + .industries_block,
    .main_block .wrapper .content .industries_block { margin-top: 60px; }
}

@media (max-width: 768px) {
    /* .main_block { height: auto; } */
    .main_block .wrapper .content { padding: 200px 0 100px; min-height: auto; }
    .main_block .wrapper .content .title { font-size: 38px; font-weight: 700; line-height: 50px; }
    .main_block .wrapper .content .description + .industries_block,
    .main_block .wrapper .content .industries_block { margin-top: 80px; column-gap: 130px; flex-wrap: wrap; row-gap: 60px; }
    .main_block .wrapper .content .industries_block::after { content: ""; flex: auto; }
}

@media (max-width: 420px) {
    .main_block .wrapper .content { padding: 167px 0 60px; }
    .main_block .wrapper .content .description + .industries_block,
    .main_block .wrapper .content .industries_block { margin-top: 60px; }
    .main_block .wrapper .content .industries_block::after { display: none; }
}