.main_block { background: linear-gradient(180deg, #01081F 0%, #18213D 88.73%); }
.main_block .wrapper { height: 100%; background-repeat: no-repeat; background-position: right top; background-size: 50%; }
.main_block .wrapper .content { display: flex; flex-direction: column; align-items: center; padding: 300px 0 180px; }
.main_block .wrapper .content .title { font-size: 50px; font-weight: 700; line-height: 65px; letter-spacing: 0.5px; text-align: center; margin-bottom: 30px; }
.main_block .wrapper .content .description { font-size: 22px; font-weight: 400; line-height: 36px; text-align: center; width: 1120px; margin-bottom: 120px; }
.main_block .wrapper .content .technologies_block { width: 100%; display: flex; justify-content: space-between; }

@media (max-width: 1536px) {
    .main_block .wrapper .content { padding: 240px 0 130px; }
    .main_block .wrapper .content .title { font-size: 46px; font-weight: 700; line-height: 60px; }
    .main_block .wrapper .content .description { font-size: 20px; font-weight: 400; line-height: 32px; width: 1076px; margin-bottom: 70px; }
}

@media (max-width: 1366px) {
    .main_block .wrapper .content { padding: 230px 0 120px; }
    .main_block .wrapper .content .title { font-size: 42px; font-weight: 700; line-height: 54px; }
    .main_block .wrapper .content .description { width: 966px; }
}