.footer_block { background: linear-gradient(100.25deg, #01081F 0.66%, #18213D 89.67%), #01081F; }
.footer_block .content .info_blocks { padding: 180px 0 80px; display: flex; justify-content: space-between; column-gap: 100px; }
.footer_block .content .info_blocks .links_block .links { display: flex; flex-direction: column; row-gap: 40px; margin: 9px 0 100px; }
.footer_block .content .info_blocks .links_block .links .link_button { font-size: 22px; font-weight: 600; line-height: 28px; }
.footer_block .content .info_blocks .links_block .socials { display: flex; align-items: center; column-gap: 12px; }
.footer_block .content .info_blocks .logo_block > img { width: 195px; height: 46px; }
.footer_block .content .rights { padding: 30px 0; text-align: center; color: #898EA3; font-size: 15px; font-weight: 400; line-height: 15px; }
.footer_block .content .cases_block { max-width: 630px; }
.footer_block .content .cases_block .block_title { padding: 9px 0 12px; border-bottom: 1px solid #575C6E; font-size: 22px; font-weight: 600; line-height: 28px; }
.footer_block .content .cases_block .cases { display: grid; grid-template-columns: 1fr 1fr; column-gap: 70px; row-gap: 30px; margin-top: 30px; }
.footer_block .content .cases_block .cases .case .case_name { margin-bottom: 8px; font-size: 18px; font-weight: 500; line-height: 24px; }
.footer_block .content .cases_block .cases .case .case_info { font-size: 15px; font-weight: 400; line-height: 18px; }
.footer_block .content .socials.mobile { display: none; }

@media (min-width: 1921px) {
    .footer_block .content .info_blocks .logo_block > img { width: 212px; height: 50px; }
    .footer_block .content .info_blocks .links_block .links .link_button { font-size: 24px; font-weight: 600; line-height: 32px; }
    .footer_block .content .cases_block .block_title { font-size: 24px; font-weight: 600; line-height: 32px; }
    .footer_block .content .cases_block .cases { margin-top: 40px; }
    .footer_block .content .cases_block .cases .case .case_name { font-size: 20px; font-weight: 500; line-height: 26px; }
    .footer_block .content .cases_block .cases .case .case_info { font-size: 16px; font-weight: 400; line-height: 18px; }
    .footer_block .content .rights { padding: 60px 0; font-size: 17px; font-weight: 400; line-height: 19px; }
}

@media (max-width: 1536px) {
    .footer_block .content .info_blocks { padding: 130px 0 50px; }
    .footer_block .content .info_blocks .links_block .links { row-gap: 24px; margin-top: 7px; }
    .footer_block .content .info_blocks .links_block .links .link_button { font-size: 20px; font-weight: 500; line-height: 26px; }
    .footer_block .content .info_blocks .logo_block > img { width: 172px; height: 40px; }
    .footer_block .content .cases_block .block_title { font-size: 20px; font-weight: 500; line-height: 26px; padding: 7px 0; }
    .footer_block .content .cases_block .cases { margin-top: 36px; row-gap: 36px; }
    .footer_block .content .cases_block .cases .case .case_name { font-size: 16px; font-weight: 500; line-height: 20px; }
    .footer_block .content .cases_block .cases .case .case_info { font-size: 12px; font-weight: 400; line-height: 18px; }
    .footer_block .content .rights { font-size: 14px; font-weight: 400; line-height: 14px; }
}

@media (max-width: 1366px) {
    .footer_block .content .info_blocks { padding: 120px 0 50px; }
}

@media (max-width: 768px) {
    .footer_block .content .info_blocks { position: relative; }
    .footer_block .content .info_blocks .links_block { position: absolute; left: 0; top: 232px; }
    .footer_block .content .info_blocks .links_block .links { row-gap: 24px; margin: 0 0 60px 0; }
    .footer_block .content .info_blocks .links_block .links .link_button { font-size: 16px; font-weight: 500; line-height: 20px; }
    .footer_block .content .cases_block .block_title { font-size: 16px; font-weight: 500; line-height: 20px; padding: 0 0 8px; }
    .footer_block .content .cases_block .cases { margin-top: 30px; row-gap: 30px; column-gap: 45px; }
    .footer_block .content .cases_block .cases .case .case_name { font-size: 14px; font-weight: 500; line-height: 18px; margin-bottom: 6px; }
    .footer_block .content .cases_block .cases .case .case_info { font-size: 11px; font-weight: 400; line-height: 14px; }
    .footer_block .content .rights { padding: 70px 0 30px; font-size: 14px; font-weight: 400; line-height: 14px; }
}

@media (max-width: 420px) {
    .footer_block .content .info_blocks { padding: 60px 0 90px; flex-direction: column; }
    .footer_block .content .info_blocks .links_block { position: static; }
    .footer_block .content .info_blocks .logo_block > img { width: 146px; height: 36px; }
    .footer_block .content .info_blocks .logo_block { margin-bottom: 60px; }
    .footer_block .content .info_blocks .links_block .links .link_button { font-size: 14px; font-weight: 500; line-height: 18px; }
    .footer_block .content .cases_block .block_title { font-size: 14px; font-weight: 500; line-height: 18px; padding: 0 0 8px; }
    .footer_block .content .cases_block .cases { margin-top: 24px; row-gap: 24px; column-gap: 45px; }
    .footer_block .content .cases_block .cases .case .case_name { font-size: 13px; font-weight: 500; line-height: 17px; margin-bottom: 4px; }
    .footer_block .content .cases_block .cases .case .case_info { font-size: 10px; font-weight: 400; line-height: 14px; }
    .footer_block .content .socials.mobile { display: flex; justify-content: center; align-items: center; column-gap: 20px; }
    .footer_block .content .socials.mobile > a > img { width: 40px; height: 40px; }
    .footer_block .content .info_blocks .links_block .socials.desktop { display: none; }
    .footer_block .content .rights { padding: 36px 0 24px; font-size: 12px; font-weight: 400; line-height: 12px; }
}