.top_menu { width: 100%; position: absolute; z-index: 1500; }
.top_menu .content { display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
.top_menu .content .logo_button { width: 155px; height: 36px; }
.top_menu .content .logo_button > div { display: none !important; }
.top_menu .content .logo_button > img { width: 100%; height: 100%; }
.top_menu .content .navigation_block,
.top_menu .content .navigation_block .navigation { display: flex; align-items: center; column-gap: 40px; }
.top_menu .content .navigation_block .navigation .navigation_button { padding: 25px 0; }
.top_menu .content .navigation_block .navigation .navigation_button,
.top_menu .content .navigation_block .navigation .navigation_button > p { letter-spacing: 1.5px; text-transform: uppercase; transition: .3s; position: relative; font-size: 13px; font-weight: 500; line-height: 40px; cursor: pointer; }
.top_menu .content .navigation_block .navigation .navigation_button.active,
.top_menu .content .navigation_block .navigation .navigation_button:hover { color: #46A1F8; }
.top_menu .content .navigation_block .navigation .navigation_button .border_bottom { opacity: 0; transition: .3s; display: block; position: absolute; z-index: -100; bottom: 0; width: 100%; height: 3px; background: #46A1F8; }
.top_menu .content .navigation_block .navigation .navigation_button.active .border_bottom,
.top_menu .content .navigation_block .navigation .navigation_button:hover .border_bottom { opacity: 1; z-index: 100; }
.top_menu .content .get_in_touch_button { background: #46A1F8; height: 40px; padding: 0 20px; text-transform: uppercase; letter-spacing: 1px; transition: .3s; font-size: 12px; font-weight: 700; line-height: 16px; }
.top_menu .content .get_in_touch_button:hover { background: #3A83FF; box-shadow: 2px 2px 20px rgba(58, 131, 255, 0.4); }
.top_menu .content .mobile_get_in_touch_button { display: none; }

.contact_us_modal { width: 100vw !important; height: 100vh; background: #FFF; max-width: 100%; top: 0; }
.contact_us_modal .content { z-index: 2000; height: 100%; display: flex; justify-content: center; align-items: center; }
.contact_us_modal .content .close_icon_button { position: absolute; top: 40px; right: 40px; }

.popover { top: 91px !important; }
.popover :global(.ant-popover-inner) { border-radius: 0; background: #19223E; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); padding: 12px 0; }
.popover .popover_content { display: flex; flex-direction: column; }
.popover .popover_content .popover_button { color: #FFF; padding: 12px 25px; transition: .3s; font-size: 16px; font-weight: 400; line-height: 16px; }
.popover .popover_content .popover_button:hover { background: #46A1F8; }

.mobile_navigation { display: none; }

:global(.mobile-top-menu-dropdown) { border-left: 1px solid #C0C4D2; position: fixed; z-index: 4000; top: 0; right: -220px; width: 220px; height: 100vh; padding-top: 100px; background: #F8F9FC; transition: .3s; }
:global(.mobile-top-menu-dropdown.collapsed) { right: 0; box-shadow: 0 0 35px rgba(0, 0, 0, 1); }
:global(.mobile-top-menu-dropdown .ant-menu-title-content) { height: 100%; font-size: 16px; font-weight: 400; line-height: 16px; color: #030A21; }
:global(.mobile-top-menu-dropdown .ant-menu.ant-menu-sub.ant-menu-inline) { background: transparent; }
:global(.mobile-top-menu-dropdown .ant-menu-item.ant-menu-item-only-child),
:global(.mobile-top-menu-dropdown .ant-menu-submenu-title) { margin: 0; width: 100%; border-radius: 0; text-align: end; }
:global(.mobile-top-menu-dropdown .ant-menu-item) { padding: 0 !important; }
:global(.mobile-top-menu-dropdown .ant-menu-item):hover { background: none !important; }
:global(.ant-menu-item-selected) { background: none !important; }
.mobile_menu_dropdown_button { width: 100%; height: 100%; display: flex; align-items: center; justify-content: flex-end; padding: 0 45px; font-size: 16px; font-weight: 400; line-height: 16px; color: #030A21; }
.mobile_menu_dropdown_button:hover,
.mobile_menu_dropdown_button.active { background: #46A1F8; color: #FFF !important; }
.mobile_menu_dropdown_button.active { font-weight: 500; }

@media (min-width: 1921px) {
    .top_menu .content .logo_button { width: 195px; height: 46px; }
    .top_menu .content .navigation_block .navigation .navigation_button { padding: 33px 0; }
    .top_menu .content .navigation_block .navigation .navigation_button,
    .top_menu .content .navigation_block .navigation .navigation_button > p { font-size: 16px; font-weight: 500; line-height: 40px; }
    .top_menu .content .get_in_touch_button { font-size: 15px; font-weight: 700; line-height: 16px; }
}

@media (max-width: 1536px) {
    .popover .popover_content .popover_button { font-size: 13px; font-weight: 400; line-height: 13px; }
}

@media (max-width: 768px) {
    .top_menu .content { padding: 30px 0; }
    .top_menu .content .logo_button { width: 155px; height: 36px; }
    .navigation { display: none !important; }
    .mobile_navigation { display: flex; width: 26px; height: 26px; justify-content: center; align-items: center; }
    .contact_us_modal .content { align-items: flex-start; }
    .contact_us_modal .content .close_icon_button { right: 45px; top: 37px; }
}

@media (max-width: 420px) {
    .top_menu .content { padding: 25px 0; }
    .top_menu .content .logo_button { width: 146px; height: 36px; }
    .top_menu .content .navigation_block,
    .top_menu .content .navigation_block .navigation { column-gap: 25px; }
    .top_menu .content .get_in_touch_button { display: none; }
    .top_menu .content .mobile_get_in_touch_button { display: flex; align-items: center; }
    .contact_us_modal .content .close_icon_button { right: 25px; top: 30px; }
    .mobile_menu_dropdown_button { padding-right: 25px; }
}

.hamburger_button.collapsed > .line { transform: rotate(45deg); }
.hamburger_button.collapsed > .line::before { top: 0; transform: rotate(0deg); }
.hamburger_button.collapsed > .line::after { top: 0; transform: rotate(90deg); }
.hamburger_button { position: relative; z-index: 5000; width: 26px; height: 26px; cursor: pointer; }
.hamburger_button { -webkit-tap-highlight-color: rgba(0,0,0,0); }
.hamburger_button > div { display: none; }
.hamburger_button > .line,
.hamburger_button > .line::before,
.hamburger_button > .line::after { display: block; position: absolute; width: 100%; height: 2px; background: #FFF; transition: .3s; }
.hamburger_button.collapsed > .line,
.hamburger_button.collapsed > .line::before,
.hamburger_button.collapsed > .line::after { background: #6B6F82; }
.hamburger_button > .line::before { content: ""; top: -8px; }
.hamburger_button > .line::after { content: ""; top: 8px; }