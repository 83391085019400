.case_main_block { width: 100%; height: 100vh; overflow: hidden; }
.case_main_block .wrapper { background-repeat: no-repeat; background-size: cover; width: 100%; height: 100%; display: flex; flex-direction: column; }
.case_main_block .wrapper .content { height: 100%; display: flex; justify-content: space-between; column-gap: 40px; }

.case_main_block .wrapper .content .image_block { flex: 1; height: 100%; position: relative; display: flex; align-items: center; }
.case_main_block .wrapper .content .image_block .image { left: auto; position: absolute; width: calc(100% + 550px); height: 100%; display: flex; justify-content: center; align-items: center; }
/* .case_main_block .wrapper .content .image_block .image > img { width: 100%; height: auto; } */
.case_main_block .wrapper .content .image_block .image > img { width: 1260px; height: auto; }

.case_main_block .wrapper .content .info_block { width: 640px; display: flex; flex-direction: column; justify-content: center; }
.case_main_block .wrapper .content .info_block .info { text-transform: uppercase; margin-bottom: 15px; color: #DDEBFE; font-size: 14px; font-weight: 600; line-height: 14px; letter-spacing: 1.5px; }
.case_main_block .wrapper .content .info_block .case_name { margin-bottom: 120px; font-size: 41px; font-weight: 700; line-height: 54px; letter-spacing: 0.5px; }
.case_main_block .wrapper .content .info_block .cases_block { display: flex; column-gap: 60px; margin-bottom: 50px; }
.case_main_block .wrapper .content .info_block .cases_block > .case { max-width: 210px; }
.case_main_block .wrapper .content .info_block .cases_block > .case > .title_block { display: flex; align-items: flex-end; margin-bottom: 8px; }
.case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p { font-family: "Montserrat"; font-size: 39px; font-weight: 600; line-height: 33px; }
.case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p:first-child { margin-right: 4px; }
.case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p:last-child { font-size: 24px; font-weight: 600; line-height: 24px; }
.case_main_block .wrapper .content .info_block .cases_block > .case > .case_info { font-size: 15px; font-weight: 400; line-height: 17px; }

.case_main_block .wrapper .content .info_block .solutions_block { display: flex; flex-wrap: wrap; column-gap: 8px; row-gap: 20px; }
.case_main_block .wrapper .content .info_block .solutions_block .solution { padding: 10px 20px; background: rgba(255, 255, 255, 0.3); border-radius: 100px; font-size: 14px; font-weight: 600; line-height: 16px; }

.case_main_block.mobile_img .wrapper .content .image_block { position: static; }
.case_main_block.mobile_img .wrapper .content .image_block .image { width: 45%; right: 70px; left: auto; }
.case_main_block.mobile_img .wrapper .content .image_block .image > img { width: 906px; height: auto; }

@media (min-width: 1921px) {
    .case_main_block { height: 1290px; }
    .case_main_block .wrapper .content .info_block { width: 660px; }
    .case_main_block .wrapper .content .info_block .info { font-size: 17px; font-weight: 600; line-height: 17px; }
    .case_main_block .wrapper .content .info_block .case_name { margin-bottom: 150px; font-size: 54px; font-weight: 700; line-height: 70px; }
    .case_main_block .wrapper .content .info_block .cases_block { margin-bottom: 60px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p { font-family: "Montserrat"; font-size: 45px; font-weight: 600; line-height: 40px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p:last-child { font-size: 26px; font-weight: 600; line-height: 26px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .case_info { font-size: 16px; font-weight: 400; line-height: 18px; }
    .case_main_block .wrapper .content .image_block .image { width: 100%; left: 100px; }
    .case_main_block.mobile_img .wrapper .content .image_block .image { justify-content: flex-end; }
    .case_main_block.mobile_img .wrapper .content .image_block { position: relative; }
    .case_main_block.mobile_img .wrapper .content .image_block .image { width: 100%; right: 0; left: auto; }
    .case_main_block.mobile_img .wrapper .content .image_block .image > img { width: 906px; }
}

@media (max-width: 1536px) {
    .case_main_block { height: 864px; }
    .case_main_block .wrapper .content .info_block .case_name { margin-bottom: 70px; }
    .case_main_block .wrapper .content .info_block .cases_block { column-gap: 50px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .case_info { font-size: 14px; font-weight: 400; line-height: 16px; }
    .case_main_block .wrapper .content .info_block .solutions_block .solution { font-size: 12px; font-weight: 600; line-height: 14px; }
    .case_main_block .wrapper .content .image_block .image { width: calc(100% + 300px); }
    .case_main_block .wrapper .content .image_block .image > img { width: 1020px; }
    .case_main_block.mobile_img .wrapper .content .image_block .image > img { width: 678px; }
}

@media (max-width: 1366px) {
    .case_main_block { height: 768px; }
    .case_main_block .wrapper .content .info_block .case_name { font-size: 40px; font-weight: 700; line-height: 52px; margin-bottom: 90px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p { font-family: "Montserrat"; font-size: 38px; font-weight: 600; line-height: 32px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p:last-child { font-size: 20px; font-weight: 600; line-height: 20px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .case_info { font-size: 14px; font-weight: 400; line-height: 16px; }
    .case_main_block .wrapper .content .info_block .solutions_block .solution { font-size: 12px; font-weight: 600; line-height: 14px; }
    .case_main_block .wrapper .content .image_block .image { width: calc(100% + 250px); }
    .case_main_block .wrapper .content .image_block .image > img { width: 880px; }
    .case_main_block.mobile_img .wrapper .content .image_block .image > img { width: 639px; }
}

@media (max-width: 768px) {
    .case_main_block { width: 100%; height: auto; }
    .case_main_block .wrapper { padding: 100px 0 0; height: 100vh; }
    .case_main_block .wrapper .content { flex-direction: column; }
    .case_main_block .wrapper .content .info_block .case_name { font-size: 38px; font-weight: 700; line-height: 50px; margin-bottom: 90px; }
    .case_main_block .wrapper .content .info_block .cases_block { width: 100%; column-gap: 50px; margin-bottom: 50px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p { font-size: 42px; font-weight: 600; line-height: 34px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p:last-child { font-size: 16px; font-weight: 600; line-height: 16px; }
    .case_main_block .wrapper .content .info_block .solutions_block { width: 100%; }
    .case_main_block .wrapper .content .image_block,
    .case_main_block.mobile_img .wrapper .content .image_block { margin-top: 0; position: static; width: 100%; display: flex; justify-content: center; }
    .case_main_block .wrapper .content .image_block .image,
    .case_main_block.mobile_img .wrapper .content .image_block .image { position: static; width: 100%; }
    .case_main_block .wrapper .content .image_block .image > img { width: 651px; }
    .case_main_block.mobile_img .wrapper .content .image_block .image > img { width: 583px; }
}

@media (max-width: 420px) {
    .case_main_block .wrapper { padding: 60px 0 0; height: auto; }
    .case_main_block .wrapper .content .info_block { width: 100%; }
    .case_main_block .wrapper .content .info_block .info { font-size: 13px; font-weight: 600; line-height: 19px; margin-bottom: 16px; }
    .case_main_block .wrapper .content .info_block .case_name { font-size: 30px; font-weight: 700; line-height: 38px; margin-bottom: 60px; }
    .case_main_block .wrapper .content .info_block .cases_block { column-gap: 90px; flex-wrap: wrap; row-gap: 24px; margin-bottom: 40px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p { font-size: 28px; font-weight: 600; line-height: 26px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .title_block p:last-child { font-size: 16px; font-weight: 600; line-height: 16px; }
    .case_main_block .wrapper .content .info_block .cases_block > .case > .case_info { font-size: 13px; font-weight: 400; line-height: 13px; }
    .case_main_block .wrapper .content .info_block .solutions_block { row-gap: 12px; }
    .case_main_block .wrapper .content .image_block,
    .case_main_block.mobile_img .wrapper .content .image_block { margin: 60px 0; }
    .case_main_block .wrapper .content .image_block .image { width: 100%; height: calc((100vw - 50px) / 2.125); }
    .case_main_block.mobile_img .wrapper .content .image_block .image { width: 100%; height: calc((100vw - 50px) / 1.5); }
    .case_main_block .wrapper .content .image_block .image > img,
    .case_main_block.mobile_img .wrapper .content .image_block .image > img { width: 100%; height: 100%; }
}