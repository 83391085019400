.case_block { border-radius: 12px; cursor: pointer; height: 380px; text-align: start; overflow: hidden; transition: .3s; }
.case_block:hover { box-shadow: 0px 0px 40px rgba(41, 54, 111, 0.5); }
.case_block .wrapper { height: 100%; display: flex; justify-content: space-between; align-items: center; background-repeat: no-repeat; background-size: cover; position: relative; }
.case_block .info_block { height: 100%; width: 55%; padding: 80px 0 80px 100px; }
.case_block .info_block .info { margin-bottom: 16px; text-transform: uppercase; color: #DDEBFE; font-size: 14px; font-weight: 600; line-height: 14px; letter-spacing: 1.5px; white-space: break-spaces; }
.case_block .info_block .case_name { margin-bottom: 70px; transition: .3s; font-size: 40px; font-weight: 700; line-height: 52px; letter-spacing: 0.5px; white-space: break-spaces; }
.case_block:hover .info_block .case_name { font-size: 44px !important; }
.case_block .info_block .cases_block { display: flex; flex-wrap: wrap; column-gap: 60px; row-gap: 16px; }
.case_block .info_block .cases_block > .case { max-width: 190px; }
.case_block .info_block .cases_block > .case > .title_block { display: flex; align-items: flex-end; margin-bottom: 12px; }
.case_block .info_block .cases_block > .case > .title_block p { font-family: "Montserrat"; font-size: 38px; font-weight: 700; line-height: 32px; }
.case_block .info_block .cases_block > .case > .title_block p:first-child { margin-right: 4px; }
.case_block .info_block .cases_block > .case > .title_block p:last-child { font-size: 20px; font-weight: 700; line-height: 20px; }
.case_block .info_block .cases_block > .case > .case_info { max-width: 100%; width: max-content; font-size: 14px; font-weight: 400; line-height: 16px; }
.case_block .img_block { height: 100%; width: 45%; position: absolute; right: 0; display: flex; justify-content: flex-end; }
.case_block .img_block img { height: 82%; width: auto; transition: .3s; }
.case_block:hover .img_block img { height: 85%; }
.case_block .img_block.center { display: flex; align-items: center; justify-content: center; }
.case_block .img_block.bottom img { position: absolute; bottom: 0; }
.case_block .img_block.right { align-items: center; }
.case_block .img_block.right img { right: 0; }

.case_block.chemwatch:hover { box-shadow: 0px 0px 40px rgba(47, 61, 123, 0.5); }
.case_block.bp_one:hover { box-shadow: 0px 0px 40px rgba(56, 138, 247, 0.5); }
.case_block.i_eat_better:hover { box-shadow: 0px 0px 40px rgba(55, 193, 253, 0.5); }
.case_block.boommy:hover { box-shadow: 0px 0px 40px rgba(3, 164, 163, 0.5); }
.case_block.eco_credit:hover { box-shadow: 0px 0px 40px rgba(23, 179, 104, 0.5); }
.case_block.feaster:hover { box-shadow: 0px 0px 40px rgba(123, 23, 208, 0.5); }
.case_block.trakopolis:hover { box-shadow: 0px 0px 40px rgba(90, 81, 89, 0.5); }

@media (max-width: 1536px) {
    .case_block { height: 364px; }
    .case_block .info_block { height: 100%; width: 60%; padding: 60px 0 60px 80px; }
    .case_block .info_block .info { font-size: 15px; font-weight: 600; line-height: 15px; }
    .case_block .info_block .case_name { font-size: 30px; font-weight: 700; line-height: 40px; }
    .case_block:hover .info_block .case_name { font-size: 38px !important; }
    .case_block .img_block { width: 40%; }
    .case_block .info_block .cases_block { column-gap: 40px; }
    .case_block .info_block .cases_block > .case > .title_block p { font-size: 48px; font-weight: 700; line-height: 40px; }
    .case_block .info_block .cases_block > .case > .case_info { font-size: 14px; font-weight: 400; line-height: 16px; }
}

@media (max-width: 1366px) {
    .case_block .info_block .case_name { font-size: 28px; font-weight: 700; line-height: 36px; }
    .case_block:hover .info_block .case_name { font-size: 36px !important; }
    .case_block .info_block .cases_block > .case > .case_info { font-size: 14px; font-weight: 400; line-height: 14px; }
}

@media (max-width: 768px) {
    .case_block { height: auto; }
    .case_block .wrapper { flex-direction: column; }
    .case_block .info_block { height: auto; width: 100%; padding: 40px 50px 0; }
    .case_block .img_block { position: static; width: 100%; margin: 50px 0 40px; }
    .case_block .img_block img,
    .case_block:hover .img_block img { width: 620px; height: auto; }
    .case_block:hover { box-shadow: none !important; }
    .case_block:hover .info_block .case_name { font-size: 28px !important; }
}

@media (max-width: 420px) {
    .case_block .info_block { padding: 30px 25px 0; }
    .case_block .info_block .case_name { margin-bottom: 40px; }
    .case_block .img_block { position: relative; width: 100%; height: auto; margin: 50px 0 40px; }
    .case_block .img_block img,
    .case_block:hover .img_block img { position: relative; height: auto; width: 100%; }

    .case_block.chemwatch .img_block,
    .case_block.eco_credit .img_block,
    .case_block.trakopolis .img_block,
    .case_block.boommy .img_block { justify-content: flex-start; }

    .case_block.chemwatch .img_block img,
    .case_block.eco_credit .img_block img,
    .case_block.boommy .img_block img { height: 240px; width: auto; }

    .case_block.trakopolis .img_block img { height: 300px; width: auto; }
}