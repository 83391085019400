.message .ant-message-notice-content {
    position: relative;
    padding: 15px 20px;
    width: 380px;
    min-height: 46px;
    height: auto;
    font-size: 13px;
    line-height: 16px;
    border-radius: 4px;
    box-shadow: 0 6px 9px rgba(144, 164, 183, 0.22);
    color: #fff;
}

.message-info .ant-message-notice-content {
    background-color: #1890FF;
}

.message-error .ant-message-notice-content {
    background-color: #E44041;
}

.message-warning .ant-message-notice-content {
    background-color: #FAA72B;
}

.message-success .ant-message-notice-content {
    background-color: #19C656;
}

.message-loading .ant-message-notice-content {
    background-color: #1890FF;
}

.message-loading .ant-message-notice-content p {
    text-align: center;
}

.message .ant-message-notice-content img {
    margin-top: -2px;
}

.message .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.message .cross-icon {
    margin-right: 12px;
    width: 16px;
}

.message .cross-icon img {
    width: 16px;
    height: 16px;
}

.message .text {
    width: 100%;
    height: 100%;
    text-align: start;
}

.message .close-icon {
    width: 12px;
}

.message .close-icon img {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.ant-message .anticon {
    display: none;
}

.ant-message .loader-spin {
    display: flex;
    width: 16px;
    height: 16px;
    color: #fFFFFF;
}

.ant-spin.ant-spin-spinning .ant-spin-dot {
    font-size: 50px;
}

.ant-spin.ant-spin-spinning {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.message-loading .ant-spin.ant-spin-spinning {
    margin: 0;
}
