.case_info_block { width: 100vw; height: 900px; padding: 140px 0; }
.case_info_block .content { display: flex; height: 100%; }
.case_info_block .content .title_block { flex: 1; height: 100%; }
.case_info_block .content .info_block { width: 800px; margin-left: 60px; }
.case_info_block .content .title_block .title { text-align: start; color: #01081F; font-size: 42px; font-weight: 700; line-height: 54px; }
.case_info_block .content .info_block { display: flex; flex-direction: column; justify-content: center; row-gap: 80px; }
.case_info_block .content .info_block .info_item .title { color: #01081F; margin-bottom: 24px; font-size: 28px; font-weight: 700; line-height: 32px; letter-spacing: 0.5px; }
.case_info_block .content .info_block .info_item .info { color: #030A21; font-size: 18px; font-weight: 400; line-height: 30px; }
.case_info_block .content .info_block .info_item .info > span { color: #030A21; }

.case_info_block.img_out .content .title_block .img_block { width: 100%; height: 100%; display: flex; align-items: center; }
.case_info_block.img_out .content .title_block .img_block img { position: absolute; width: 733px; height: auto; left: 60px; }

.case_info_block.web .content .info_block { margin-left: 0; }
.case_info_block.img_out.web .content .title_block .img_block { position: relative; }
.case_info_block.img_out.web .content .title_block .img_block img { width: 1260px; height: auto; right: 0; left: auto; }
.case_info_block.img_out.web:global(.boommy-web) .content .title_block .img_block img { width: 577px; margin-right: 138px; }

@media (min-width: 1921px) {
    .case_info_block { height: 1290px; }
    .case_info_block .content .info_block { row-gap: 100px; }
    .case_info_block .content .info_block .info_item .title { font-size: 32px; font-weight: 700; line-height: 36px; }
    .case_info_block .content .info_block .info_item .info { font-size: 24px; font-weight: 400; line-height: 38px; }
    .case_info_block.img_out .content .title_block .img_block { justify-content: flex-start; }
    .case_info_block.img_out .content .title_block .img_block img { width: 733px; height: auto; position: static; }
    .case_info_block.img_out.web .content .title_block .img_block img { width: 1260px; height: auto; position: absolute; }
    .case_info_block.img_out.web .content .title_block .img_block img { margin-right: 150px; }
    .case_info_block.img_out.web:global(.boommy-web) .content .title_block .img_block img { width: 947px; margin-right: 256px; }
}

@media (max-width: 1536px) {
    .case_info_block { height: 864px; }
    .case_info_block .content .title_block .title { font-size: 38px; font-weight: 700; line-height: 42px; }
    .case_info_block .content .info_block { row-gap: 60px; margin-left: 40px; width: 700px; }
    .case_info_block .content .info_block .info_item .title { font-size: 26px; font-weight: 700; line-height: 28px; }
    .case_info_block .content .info_block .info_item .info { font-size: 18px; font-weight: 400; line-height: 28px; }

    .case_info_block.img_out .content .title_block .img_block img { width: 576px; left: 40px; }
    .case_info_block.img_out.web .content .title_block .img_block img { width: 1020px; right: -50px; }
    .case_info_block.img_out.web:global(.boommy-web) .content .title_block .img_block img { width: 542px; margin-right: 56px; right: 0; }
}

@media (max-width: 1366px) {
    .case_info_block { height: 768px; }
    .case_info_block .content .title_block .title { font-size: 34px; font-weight: 700; line-height: 38px; }
    .case_info_block .content .info_block { row-gap: 50px; margin-left: 70px; width: 650px; }
    .case_info_block .content .info_block .info_item .title { font-size: 24px; font-weight: 700; line-height: 26px; }
    .case_info_block .content .info_block .info_item .info { font-size: 16px; font-weight: 400; line-height: 24px; }

    .case_info_block.img_out .content .title_block .img_block img { width: 476px; left: 70px; }
    .case_info_block.img_out.web .content .title_block .img_block img { width: 880px; right: 0; }
    .case_info_block.img_out.web:global(.boommy-web) .content .title_block .img_block img { width: 415px; margin-right: 100px; }
}

@media (max-width: 768px) {
    .case_info_block { padding: 100px 0; width: 100%; height: auto; }
    .case_info_block .content { flex-direction: column; }
    .case_info_block .content .title_block { display: none; }
    .case_info_block .content .info_block { margin: 0; width: 100%; }
}

@media (max-width: 420px) {
    .case_info_block { padding: 60px 0; }
    .case_info_block .content .info_block .info_item .title { font-size: 20px; font-weight: 700; line-height: 22px; }
}